import  { DEFAULT_PAGE_SIZE } from "../bundleListTable/Table";
import { useEffect, useState } from "react";
import {Grid, Paper, TableBody, TableCell, TableRow,Table} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Link, useLocation, useNavigate} from "react-router-dom";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { AsyncQueryStatus, AsyncStore ,AsyncStoreResult} from "@dtx/ui-core/src/query";
import '../shared/styles/loadingStyles.css';
import {BundleList} from "../BundleListForm/BundleList";
import BeforeBundlesTable from "./BeforeBundlestable";
import BundlesTableAppBar from "./bundlesTableAppBar";

interface Props {
  filter?: string
  store: AsyncStore
}

interface DataInterface {
  data: BundleList[];
  'result-count': number;
  'total-count': number;
  'page-number': number;
  'total-pages': number;
}

export default function BundlesTable(props: Props) {
  const [numOfSelectedRows,setNumOfSelectedRows] = useState(0)
  const [selectedRows,setSelectedRows]: any[] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const bundleList = location.state?.bundleList || null
  const [filter, setFilter] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [refreshKey, setRefreshKey] = useState(0)

  let searchTimeOutId: number = -1
  // useEffect(() => {
  //     result.refetch()
  // }, [refreshKey])

  const key = `bundles-list-${rowsPerPage}-${filter}-${selectedStatus}-${pageNumber}`
  // const result: AsyncStoreResult<DataInterface | null> = props.store.addQuery(
  //     key,
  //     async () => {
  //         const encodedFilter = encodeURIComponent(filter)
  //         const encodedStatus = encodeURIComponent(selectedStatus)
  //         const response = await fetch(`/api/bundle-lists?numOfItems=${rowsPerPage}&page=${pageNumber}&sortBy=id&sortOrder=DESC&filters[text]=${encodedFilter}&filters[state]=${encodedStatus}`, {
  //             method: 'get',
  //             mode: 'cors',
  //         });
  //         if (response && response.status === 200) {
  //             const json = await response.json();
  //             return json;
  //         }
  //
  //         throw new Error('Error fetching data');
  //     }
  // );
  interface MockResultBundleList {
    data: {
      data: BundleList;
      resultCount: number;
      totalCount: number;
      pageNumber: number;
      totalPages: number;
      status: string
    }
  }
  const result2: MockResultBundleList = {
    data: {
      data:  bundleList,
      resultCount: 2,
      totalCount: 2,
      pageNumber: 1,
      totalPages: 1,
      status: "Resolved"
    }
  };


  const clearSearchDebounce = () => {
    if (searchTimeOutId != -1) {
      clearTimeout(searchTimeOutId)
      searchTimeOutId = -1
    }
  }

  const handleFilterChange = (value) => {
    clearSearchDebounce()
    searchTimeOutId = setTimeout(function() {
      clearSearchDebounce()
      setFilter(value);
      setPageNumber(1)
      setTotalPages(1)
    }, 500)

  };


  const handleRowClick = (bundle: string) => {
    const toAddSelectedRows = !selectedRows.includes(bundle)
    setSelectedRows((prevSelectedRows) => {
      if (toAddSelectedRows) {
        return [...prevSelectedRows, bundle]
      } else {
        return prevSelectedRows.filter((row) => row !== bundle)
      }
    })

    setNumOfSelectedRows((prevNumOfSelectedRows) => prevNumOfSelectedRows + (toAddSelectedRows ? 1 : -1))
  }
  const handlePageChange = (pageIndex: number) => {

    const newPage = pageIndex + 1;
    setPageNumber(newPage)
  };


  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };




  dayjs.extend(utc);


  return (
      <Grid container >
        <Grid item   style={{paddingTop: "70px",paddingLeft: "20px", width: "90%"}} >
          <Grid item>
            {"Bundle List Details"}
          </Grid>
          <Paper>
            <BeforeBundlesTable  title={"Bundle List Details"} placeholder={"Search in this Bundle list"}  buttonOnClick={() => console.log("button add bundle list")}  bundleList={bundleList} onFilterChange={handleFilterChange}/>
            <Divider style={{marginTop: 10}} />
            <BundlesTableAppBar numOfSelected={numOfSelectedRows} onClearSelection={() => {
              setSelectedRows([])
              setNumOfSelectedRows(0)
            }} selectedBundles={selectedRows}/>
            <Table >
              <TableBody>
                {bundleList.bundles.map((item, index) => (
                    <TableRow key={index}   >
                      <TableCell onClick={() => handleRowClick(item)}>{item}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
  )
}
