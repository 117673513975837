import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import CustomMenuItem from "./CustomMenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import {CopyText} from "../shared/CopyText";


const StyledAppBar = styled(AppBar)`
  background-color: #0000;
  border-bottom: 1px solid #e1e1e1;
  box-shadow: none;
`;

interface BundlesTableAppbarProps {
  numOfSelected: number
  selectedBundles: string[]
  onClearSelection: () => void
}


function BundlesTableAppbar(props: BundlesTableAppbarProps) {
    const {onClearSelection, numOfSelected, selectedBundles} = props
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

    const handleDownloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," + selectedBundles.map(bundle => `${bundle}`).join("\n")
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", "bundles.csv")
        document.body.appendChild(link)
        link.click()
    };
  return (
    <StyledAppBar >
      <Container maxWidth="xl"  sx={{
          display: { margin:0, padding: 0 },
      }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none', margin:0 ,maxWidth:"xl"},
              }}
            >
            </Menu>
          <Box>
            <CustomMenuItem  text={` ${props.numOfSelected} selected`} textColor={"#000000"}/>
            <CustomMenuItem text={"Clear Selection"} onClick={onClearSelection}/>
            <CustomMenuItem text={"Remove Selected IDs"} icon={<DeleteIcon/>}  onClick={() => {console.log("remove")}}/>
            <CustomMenuItem text={"Download selection as CSV"} icon={<DownloadIcon/>} onClick={handleDownloadCSV}/>
            <CustomMenuItem text={"Copy selected IDs"} icon={<CopyText text={selectedBundles.join('\n')}/>}/>
          </Box>
      </Container>
    </StyledAppBar>
  );
}
export default BundlesTableAppbar;
