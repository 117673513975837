import { Grid, Typography } from "@mui/material";
import { BundleListIdCopy } from "./BundleListIdCopy";
import {CopyText} from "../shared/CopyText";

export interface BundleListNameCellProps {
  name?: string
  createdBy: string
  id: string
  date?: string
}

export function BundleListNameCell(props: BundleListNameCellProps) {
  const { name, createdBy, id,date } = props;
  const dateText =  date !== undefined ? `on ${date}` : ""
  return (
      <Grid container display={"flex"} style={{ display: "absolute" }}>
        <Grid container direction="row" alignItems={"flex-end"}>
        <Grid  item spacing={1} alignItems="center">
          <Typography variant="inherit" color={"#8E8E8E"}>
            {`Created By ${createdBy} ${dateText}          ${id}`}
          </Typography>
        </Grid>
        <Grid item style={{color: "#8E8E8E",fontSize: "small" }}>
        <CopyText text={id}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
