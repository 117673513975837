import Button from "@mui/material/Button";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

interface CustomMenuItemProps {
  icon?: React.ReactNode;
  text: string;
  onClick?: () => void;
  textColor?: string
}

const CustomMenuItem = (props: CustomMenuItemProps) => {
  const { icon, text, onClick, textColor } = props;

  return (
    <Button key={text} onClick={onClick} style={{color: textColor ? textColor : "#8f93b3"}}>
      <MenuItem >
        { icon && <div >
          <ListItemIcon style={{color: textColor ? textColor :  "#8f93b3"}}>{icon}</ListItemIcon>
        </div>}
        <Typography variant="inherit" >{text}</Typography>
      </MenuItem>
    </Button>
  );
};

export default CustomMenuItem
