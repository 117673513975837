import * as React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { ExpandMore as ExpandMoreIcon, FormatListBulleted as FormatListBulletedIcon } from "@mui/icons-material";

export interface ExportListDropDownProps {
        bundles: string[]
}

export function ExportListDropDow(props: ExportListDropDownProps) {
    const [anchorElement, setAnchorElement] = React.useState(null);
  const isOpen = Boolean(anchorElement);

    const handleMenuOpen = (event) => {
      setAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorElement(null);
    };

    const handleDownloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," + props.bundles.map(bundle => `${bundle}`).join("\n")
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement("a")
        link.setAttribute("href", encodedUri)
        link.setAttribute("download", "bundles.csv")
        document.body.appendChild(link)
        link.click()
        handleMenuClose()
    };

    const handleCopyBundlesID = () => {
        const textToCopy = props.bundles.join("\n")
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log("Bundles IDs copied to clipboard:", textToCopy)
            })
            .catch((error) => {
                console.error("Failed to copy bundles IDs:", error)
            });
      handleMenuClose()
    };

    return (
      <div>
        <Button
          id="export-list-button"
          aria-controls={isOpen ? "export-list-menu" : undefined}
          // aria-haspopup="true"
          aria-expanded={isOpen ? "true" : undefined}
          onClick={handleMenuOpen}
          variant="outlined"
          color="primary"
          endIcon={<ExpandMoreIcon />}>
          <FormatListBulletedIcon />
          Export list
        </Button>
        <Menu
          id="export-list-menu"
          anchorEl={anchorElement}
          open={isOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'export-list-button',
        }}
          PaperProps={{
            style: {
              paddingRight: "10px",
                height: "inherit"
            },
          }}
        >
          <MenuItem onClick={handleDownloadCSV}>Download as CSV</MenuItem>
          <MenuItem onClick={handleCopyBundlesID}>Copy Bundle IDs</MenuItem>
        </Menu>
      </div>
    );
}
