import { Button, Grid, SxProps } from "@mui/material";
import { ReactElement, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import SearchField from "./SearchField";

interface BeforeTableProps {
  sx?: SxProps
  title: string
  children?: ReactElement[]
  placeholder?: string
  buttonOnClick?: () => void
  onTextFilterChanged?: () => void
}

const BeforeTable = (props: BeforeTableProps) => {
  const {title,placeholder,buttonOnClick, onTextFilterChanged} = props
  const [filter, setFilter] = useState<string>('')
    const handleFilterChange = (value: string) => {
        setFilter(value);

        onTextFilterChanged?.(value);
    };
  return (
    <Grid
      container
      justifyContent={"space-between"}
      style={{ display: "absolute" }}
      alignItems={"center"}
    >
     <Grid container  direction={"row"}  justifyContent={"space-between"} alignItems={"flex-end"} style={{padding:"10px"}}>
          <Grid item >
            <SearchField
              size="small"
              placeholder={`Search ${placeholder}`}
              shortcutKeys={['/']}
              value={filter}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item>
            <Button onClick={buttonOnClick} variant={"contained"} color={"primary"}>
              <AddIcon/>
              {`Add ${placeholder}`}
            </Button>
          </Grid>
        </Grid>
    </Grid>
  )
}


export default BeforeTable
