import Table, { DEFAULT_PAGE_SIZE } from "../bundleListTable/Table";
import { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { DealsActionsCell } from "../dealsForm/dealsActionsCell";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { AsyncQueryStatus, AsyncStore ,AsyncStoreResult} from "@dtx/ui-core/src/query";
import '../shared/styles/loadingStyles.css';
import {BundleList} from "../BundleListForm/BundleList";
import {BundleListNameCell} from "./BundleListNameCell";
import BeforeTable from "./BeforeTable";

interface Props {
    filter?: string
    store: AsyncStore
}

interface DataInterface {
    data: BundleList[];
    'result-count': number;
    'total-count': number;
    'page-number': number;
    'total-pages': number;
}

export default function BundleListTable(props: Props) {

    const navigate = useNavigate()
    const [filter, setFilter] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0)
    const [refreshKey, setRefreshKey] = useState(0)

    let searchTimeOutId: NodeJS.Timeout = -1
    useEffect(() => {
        result.refetch()
    }, [refreshKey])

    const key = `bundle-lists-${rowsPerPage}-${filter}-${selectedStatus}-${pageNumber}`
    const result: AsyncStoreResult<DataInterface | null> = props.store.addQuery(
        key,
        async () => {
            const encodedFilter = encodeURIComponent(filter)
            const encodedStatus = encodeURIComponent(selectedStatus)
            const response = await fetch(`/api/bundle-lists?numOfItems=${rowsPerPage}&page=${pageNumber}&sortBy=id&sortOrder=DESC&filters[text]=${encodedFilter}&filters[state]=${encodedStatus}`, {
                method: 'get',
                mode: 'cors',
            });
            if (response && response.status === 200) {
                const json = await response.json();
                return json;
            }

            throw new Error('Error fetching data');
        }
    );
    interface MockResultBundleList {
        data: {
            data: BundleList[];
            resultCount: number;
            totalCount: number;
            pageNumber: number;
            totalPages: number;
            status: string
        }
    }
    const result2: MockResultBundleList = {
        data: {
            data: [
                {
                    "bundle-list-name": "bundleList1",
                    "created-by": "shahar kimhi",
                    id: "143434",
                    "last-update": dayjs().format('DD/MM/YY'),
                    bundles: ["com.blabla", "58279047803456780t34"],
                },
                {
                    "bundle-list-name": 'bundleList2',
                    "created-by": "shahar kimhi",
                    id: "143435",
                    "last-update": dayjs().format('DD/MM/YY'),
                    bundles: ["58279047803456780t34", "com.blabla"],
                }
            ],
            resultCount: 2,
            totalCount: 2,
            pageNumber: 1,
            totalPages: 1,
            status: "Resolved"
        }
    };


    const clearSearchDebounce = () => {
        if (searchTimeOutId != -1) {
            clearTimeout(searchTimeOutId)
            searchTimeOutId = -1
        }
    }

    const handleFilterChange = (value) => {
        clearSearchDebounce()
        searchTimeOutId = setTimeout(function() {
            clearSearchDebounce()
            setFilter(value);
            setPageNumber(1)
            setTotalPages(1)
        }, 500)

    };



    const handlePageChange = (pageIndex: number) => {

        const newPage = pageIndex + 1;
        setPageNumber(newPage)
    };
    const createNewBundleList = () => {
        navigate('/bundle-list/add')
    }

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage)
    };


    const editBundleList = async (id: string) => {
        let bunbleListToEdit = result2.data!.data.find((element) => element.id === id)

        if (bunbleListToEdit) {
            navigate(`/bundle-list/edit/${id}`, { state: { bundleList: bunbleListToEdit, refreshKey: key}})
        } else {
            console.error(`Bundle List with id ${id} not found`)
        }
    }



    const handleRowClick = (bundleList: BundleList) => {
        const bundleListId = bundleList.id
        if(bundleListId) {
            navigate(`/bundle-lists/${bundleListId}`, { state: { bundleList: bundleList, refreshKey: key}})
        }
        // setSelectedRow(bundleList)
    }


    dayjs.extend(utc);

    const formatDate = (isoDate) => {
        if (isoDate) {
            // Parse the ISO date string as UTC and format it
            return dayjs.utc(isoDate).format('DD-MM-YYYY');
        }
        return '';
    };

    const columns = [
        // { id: 'bundleListName',size: 80, accessor:'bundleListName', header: 'Bundle List Name',cell: ({ row }) => {
        //         return (<div style={{color: 'inherit', textDecoration: "none"}}><Link to={`/bundle-lists/${row.original.id}`} style={{color: 'inherit', textDecoration: "none"}}>
        //             <BundleListNameCell name={row.original["bundle-list-name"]} createdBy={row.original["created-by"]} id={row.original.id}/>
        //         </Link></div>)}
        // },
        { id: 'bundleListId',size: 60, accessor: 'bundleListId', header: 'Bundle List ID',cell: ({ row }) =>{
                return  row.original["id"]}
        },
        { id: 'bundleListName',size: 60, accessor: 'bundleListName', header: 'Bundle List Name',cell: ({ row }) =>{
                return  row.original["bundle-list-name"]}
        },
        { id: 'createdBy',size: 60, accessor: 'createdBy', header: 'Created By',cell: ({ row }) =>{
                return  row.original["created-by"]}
        },
        { id: 'lastUpdate',size: 60, accessor: 'lastUpdate', header: 'Last Update',cell: ({ row }) =>{
                return  row.original["last-update"]}
        },
        { id: 'bundles',size: 60, accessor: 'bundles', header: 'Bundles',cell: ({ row  }) => {
                return `${row.original.bundles.length} Bundles`}
        },
        {
            id: 'actions',
            display: 'actions',
            size: 5,
            cell: ({ row }) => {
                return <DealsActionsCell row={row.original} actionsNames={["Duplicate Bundle List","Edit Bundle List"]} callbackActions={[() => {
                    editBundleList(row.original['id'])
                }]} onUpdate={ result.refetch} />;
            },
        },
    ];

    return (
        <Grid container>
            <Grid item  style={{ paddingTop: '70px', width: '100%' }}>
                <Paper style={{ overflowX: 'auto' }}>
                    <BeforeTable title={"Bundle Lists"} placeholder={"Bundle Lists"} buttonOnClick={() => createNewBundleList()} onTextFilterChanged={handleFilterChange}/>
                    <Divider />
                    {/*{result.status === AsyncQueryStatus.loading || result.isFetching || result.isPending ? (*/}
                    {/*    <div id="load">*/}
                    {/*        <div>G</div>*/}
                    {/*        <div>N</div>*/}
                    {/*        <div>I</div>*/}
                    {/*        <div>D</div>*/}
                    {/*        <div>A</div>*/}
                    {/*        <div>O</div>*/}
                    {/*        <div>L</div>*/}
                    {/*    </div>*/}
                    {/*) */}
                    {/*    :  result2.status === AsyncQueryStatus.error || result2.status === undefined ? (*/}
                    {/*    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>*/}
                    {/*        There was an error. Please try again.*/}
                    {/*    </div>*/}
                    {/*) :*/}
                    {/*        (*/}
                    <div style={{ overflowX: 'auto' }}>
                        <Table
                            id="bundle-lists-table"
                            data={result2.data.data ?? []}
                            columns={columns}
                            totalCount={result2.data["total-count"]}
                            totalPages={result2.data["total-pages"]}
                            currentPage={result2.data["page-number"]}
                            rowPerPage={rowsPerPage}
                            onPageChange={handlePageChange}
                            manualPagination={true}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            getRowProps={(row) => {
                                const bundleList = row.original;
                                return {
                                    style: {
                                        cursor: 'pointer',
                                    },
                                    onClick: () => handleRowClick(bundleList),
                                };
                            }}
                        />
                    </div>
                    {/*)*/}
                    {/*}*/}
                </Paper>
            </Grid>
        </Grid>
    );
}
