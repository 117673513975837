import {Button, Grid, IconButton, SxProps, Typography} from "@mui/material";
import { ReactElement, useState } from "react";
import  AddIcon  from "@mui/icons-material/Add";
import SearchField from "./SearchField";
import { ActionsCell } from "./ActionsCell";
import { ExportListDropDow } from "./ExportListDropDown";
import { BundleListNameCell } from "./BundleListNameCell";
import {Check, Edit} from '@mui/icons-material';
import {BundleList} from "../BundleListForm/BundleList";
import TextField from "@mui/material/TextField";

interface BeforeBundlesTableProps {
  sx?: SxProps
  title: string
  children?: ReactElement[]
  placeholder?: string
  buttonOnClick?: () => void
  bundleList: BundleList
  onFilterChange?: (filter: string) => void;
}

const BeforeBundlesTable = (props: BeforeBundlesTableProps) => {
  const {title,placeholder,buttonOnClick,bundleList, onFilterChange} = props
  const [filter, setFilter] = useState<string>('')
    const lastUpdate = bundleList[ "last-update"]
    const [bundleNameValue, setBundleNameValue] = useState(bundleList[ "bundle-list-name"]);
    const [isEditingBundleName, setIsEditingBundleName] = useState(false)
    const handleFilterChange = (value: string) => {
        setFilter(value);

        onFilterChange?.(value);
    };

    const handleEditClick = () => {
        setIsEditingBundleName(true);
    };

    const handleInputChange = (e) => {
        setBundleNameValue(e.target.value);
    };

    const handleSaveClick = () => {
        setIsEditingBundleName(false);
        // Perform save operation, e.g., update backend
    };
  return (
      <>
          <Grid container justifyContent={"space-between"} direction="row" alignItems={"flex-end"} style={{padding: 10}}>
              <Grid item xs={12} sm={6} md={7} >
                  {isEditingBundleName ? (
                      <Grid item display={"flex"}>
                          <TextField
                            style= {{
                              fontSize: 'inherit',
                              width: 'auto',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            variant="standard"
                            value={bundleNameValue}
                                 onChange={handleInputChange}
                                 onBlur={handleSaveClick}
                          />
                          <IconButton onClick={handleSaveClick}><Check /></IconButton>
                      </Grid>
                  ) : (
                      <Grid container direction="row">
                              <Typography variant="body1" fontWeight="500"  style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                              }} >
                                  {bundleNameValue}
                              </Typography>
                              <IconButton onClick={handleEditClick} style={{padding:0, marginLeft: 10}}><Edit /></IconButton>
                      </Grid>
                  )}
              </Grid>
              <Grid item xs={12} sm={6} md={5} container justifyContent="flex-end"  spacing={2}>
                  <Grid item>
                      <SearchField
                          size="small"
                          padding={"0px"}
                          height={"42px"}
                          placeholder={`${placeholder}`}
                          shortcutKeys={['/']}
                          value={filter}
                          onChange={handleFilterChange}
                      />
                  </Grid>
                  <Grid item>
                      <ExportListDropDow bundles={bundleList.bundles}/>
                  </Grid>
                  <Grid item>
                      <Button onClick={buttonOnClick} variant={"contained"} color={"primary"}>
                          <AddIcon />
                          {`Add Bundle IDs`}
                      </Button>
                  </Grid>
                  <Grid item>
                      <ActionsCell row={bundleList} actionsNames={["Duplicate Bundle List", "Clear Bundle List"]} callbackActions={[() => { }, () => { }]} />
                  </Grid>
              </Grid>
          </Grid>
          <Grid item style={{ paddingLeft: "10px" }}>
              <BundleListNameCell  createdBy={bundleList["created-by"]} id={bundleList.id} date={lastUpdate} />
          </Grid>
</>
  )
}


export default BeforeBundlesTable
