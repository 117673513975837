import { styled, Tooltip } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import Box from "@mui/material/Box";
import FileCopyIcon from '@mui/icons-material/FileCopy';

const TOOLTIP_DURATION = 1_000 // ms

interface CopyTextProps {
  text: string
}

export  function CopyText(props: CopyTextProps) {
  const [copied, setCopied] = useState<boolean>(false)

  function handleCopy(event: MouseEvent) {
    event.stopPropagation()
    if (clipboard.supported) {
      clipboard.copyText(props.text.toString())
      setCopied(true)
      setTimeout(() => setCopied(false), TOOLTIP_DURATION)
    }
  }

  return (
    <Tooltip open={copied} title="Copied">
      <Wrapper onClick={handleCopy} >
        <FileCopyIcon />
      </Wrapper>
    </Tooltip>
  )
}

const Wrapper = styled(Box)`
  width: fit-content;
  align-items: center;
  color: '#6e6e6e';
  padding: 2px 4px;
  border-radius: 4px;
  gap: 8px;
  display: flex;

  &:hover {
    background-color: '#f5f5f5';
    outline: '1px solid #e1e1e1';
    cursor: pointer;
  }

  & > .copy-icon {
    visibility: visible;
  }

  &:hover > .copy-icon {
    visibility: visible;
  }
`

const clipboard = {
  get supported() {
    return navigator.clipboard?.writeText !== undefined
  },
  copyText,
}

function copyText(text: string): Promise<void> {
  if (!clipboard.supported) {
    throw new Error('Clipboard is not supported in your browser')
  }

  return navigator.clipboard.writeText(text)
}

export default clipboard
